<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :height="height"
    viewBox="0 0 37 37"
  >
    <g
      id="Group_7324"
      data-name="Group 7324"
    >
      <rect
        id="Rectangle_2516"
        data-name="Rectangle 2516"
        width="36"
        height="36"
        fill="none"
      />
      <path
        id="Wheel_Tyre_03"
        data-name="Wheel Tyre 03"
        d="M9.947,22h0a10.98,10.98,0,0,1-5.981-2.475V18.034H2.475A10.97,10.97,0,0,1,0,12.052L1.053,11,0,9.947A10.971,10.971,0,0,1,2.475,3.966H3.966V2.475A10.971,10.971,0,0,1,9.947,0L11,1.053,12.052,0a10.97,10.97,0,0,1,5.981,2.475V3.966h1.492A10.978,10.978,0,0,1,22,9.947L20.948,11,22,12.052a10.973,10.973,0,0,1-2.475,5.981H18.034v1.492A10.977,10.977,0,0,1,12.053,22L11,20.948,9.947,22ZM11,4.236A6.765,6.765,0,1,0,17.764,11,6.772,6.772,0,0,0,11,4.236Zm0,12.637a5.709,5.709,0,0,1-1.847-.307c-.427-.215-.57-.347-.549-.506a10.666,10.666,0,0,1,1.483-2.087l.058-.051a1.221,1.221,0,0,1,.811-.389,1.194,1.194,0,0,1,.911.527,13.521,13.521,0,0,1,1.407,1.967c.015.288-.223.5-.726.638A5.557,5.557,0,0,1,11,16.872ZM6.923,14.935c-.212,0-.434-.162-.677-.495a5.351,5.351,0,0,1-.738-1.377,5.656,5.656,0,0,1-.361-1.835c.052-.476.126-.657.283-.692a.285.285,0,0,1,.064-.006,12.506,12.506,0,0,1,2.409.661l.066.035a1.228,1.228,0,0,1,.65.624,1.186,1.186,0,0,1-.174,1.04A13.833,13.833,0,0,1,7.1,14.9.433.433,0,0,1,6.923,14.935Zm8.223-.055a.2.2,0,0,1-.092-.022A10.721,10.721,0,0,1,13.533,12.8l-.028-.063a1.221,1.221,0,0,1-.118-.9,1.189,1.189,0,0,1,.786-.7,15.639,15.639,0,0,1,2.27-.725.14.14,0,0,1,.036,0c.277.074.4.364.38.887a5.608,5.608,0,0,1-.285,1.535,5.676,5.676,0,0,1-.87,1.658C15.43,14.771,15.274,14.88,15.147,14.88ZM10.9,11.858a.78.78,0,1,1,.78-.78A.782.782,0,0,1,10.9,11.858ZM8.884,9.165a1.842,1.842,0,0,1-.429-.056A13.672,13.672,0,0,1,6.142,8.4c-.186-.221-.124-.531.19-.949a5.5,5.5,0,0,1,1.1-1.106A5.713,5.713,0,0,1,9.087,5.47a2.5,2.5,0,0,1,.447-.055c.157,0,.248.038.3.123A10.943,10.943,0,0,1,9.921,8.1c0,.022-.009.045-.013.07a1.223,1.223,0,0,1-.409.807A.968.968,0,0,1,8.884,9.165Zm4.177-.041a.989.989,0,0,1-.583-.168A1.182,1.182,0,0,1,12.032,8,14.182,14.182,0,0,1,12,5.585a.472.472,0,0,1,.438-.216,1.658,1.658,0,0,1,.521.106A5.744,5.744,0,0,1,15.687,7.5c.231.418.274.608.164.728a10.933,10.933,0,0,1-2.409.867l-.068.008A2.441,2.441,0,0,1,13.061,9.124Z"
        transform="translate(2 3) scale(1.5)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 37,
    },
  },
};
</script>